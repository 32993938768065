.drawer-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        color: #40a9ff;
    }
}


.secondary-button {
    .add-icon {
        font-size: 10px;
        path {
            fill: black;
        }
    }
    &:hover {
        .add-icon {
            font-size: 10px;
            path {
                fill: #40a9ff;
            }
        }
    }
}

iframe#webpack-dev-server-client-overlay {
  display: none !important
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-table .ant-table-thead .ant-table-cell {
  background-color: #2563EB !important;
  color: #FFFFFF !important;
}

.ant-layout-sider {
  background-color: #fff !important;
}

.ant-table-thead>tr>th {
  border-right: 1px solid #FFFFFF !important;
}
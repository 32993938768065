@import "~antd/dist/antd.css";

@font-face {
	font-family: "Lato";
	src: local("Lato"), url(../font/Lato/Lato-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "Inter";
	src: local("Inter"), url(../font/Inter/static/Inter-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "Roboto";
	src: local("Roboto"), url(../font/Roboto/Roboto-Regular.ttf) format("truetype");
}

html {
	overflow: hidden;
}

body {
	/* max-width: 1440px!important; */
	margin: auto !important;
	width: 100% !important;
}

#root {
	height: 100%;
	font-family: Inter, Roboto, Lato, sans-serif, Arial, Helvetica !important;
}

.font-size-28 {
	font-size: 28px;
}

.font-size-24 {
	font-size: 24px;
}

.font-size-22 {
	font-size: 22px;
}

.font-size-18 {
	font-size: 18px;
}

.font-size-16 {
	font-size: 16px;
}

.cursor-pointer {
	cursor: pointer;
	color: #FFFFFF !important;
}

.text-center {
	text-align: center;
}

.text-red {
	color: red;
}

.layout-content {
	background-color: #F2F4FD;
}

.background-white {
	background-color: white;
}

.recharts-wrapper {
	position: absolute !important;
}

.text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 200px;
}

// Overflow text
.text-overflow-custom {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.file-item {
	color: #1890ff;
}

.file-item:hover {
	text-decoration: underline;
}

.ant-table-tbody>tr:not(.ant-table-measure-row)>td,
.ant-table-thead>tr>th,
.ant-table tfoot>tr>td,
.ant-table tfoot>tr>th {
	padding: 12px !important;
}

.custom-upload-file {
	max-height: 225px;
	padding-right: 10px;
	overflow: auto;
}

.custom-upload-file .ant-upload-list {
	max-height: 225px;
	padding-right: 10px;
	overflow: auto;
}

.custom-view-file {
	max-height: 310px;
	padding-right: 10px;
	overflow: auto;
}

/* Button */
.btn {
	border: 1px solid #e5e7eb;
	box-sizing: border-box;
	box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
	border-radius: 4px;
	padding: 8px 16px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-family: Inter;
	font-size: 14px;
	color: #ffffff !important;
}

.btn-blue {
	background: #1e40af !important;
}

.btn-indigo {
	background: #5048e5 !important;
}

/*buttons*/
.btn-red {
	background: #dc2626 !important;
}

/* Button */

/* Tab */
.ant-tabs-tab .ant-tabs-tab-btn {
	font-size: 16px;
	font-weight: 600;
}

/* Tab */

/* Scrollbar */
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(50, 50, 50, 0.3);
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgba(50, 50, 50, 0.6);
}

::-webkit-scrollbar-track {
	background-color: rgba(50, 50, 50, 0.1);
}

::-webkit-scrollbar-track:hover {
	background-color: rgba(50, 50, 50, 0.2);
}

/* End Scrollbar	*/

.margin-none {
	margin: 0 !important;
}

.ant-modal-footer {
	text-align: center !important;
}

.action-button {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
}

.content-box .ant-pagination-prev button,
.content-box .ant-pagination-next button {
	margin-left: 0px;
}

/* popover button for tables */
.popover-button {
	border: none;
	background: transparent;
	color: #374151;
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 10px;
}

.popover-button:last-child {
	margin-bottom: 0px !important;
}

.popover-button:hover,
.popover-button:active,
.popover-button:focus {
	color: #40a9ff;
}

/*temp gray background ONLY*/
.ant-layout.ant-layout-has-sider>.ant-layout {
	background: #39C57D;
	/*need to find alternative for height*/
	/* alternative found */
	// min-height: 2000px;
}

/*asterisk*/
.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	display: none !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
	display: inline;
	margin-right: 4px;
	color: #ff4d4f;
	font-size: 14px;
	font-family: Inter;
	line-height: 1;
	content: "*";
}

.title-label {
	font-family: Inter;
	font-weight: 600;
	font-size: 18px;
	color: #000000;
	margin-left: 20px;
}

.guide-title {
	font-weight: 600;
	font-size: 30px;
	color: #000000;
	margin-left: 20px;
}

/*content-box*/
.content-box {
	background: #ffffff;
	border-radius: 8px;
	padding: 20px;
	margin: 0 20px;
	font-family: Inter;

	.gray-button {
		border: 1px solid #c4c4c4;
		box-sizing: border-box;
		box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
		border-radius: 4px;
		font-family: Inter;
		color: #52525b;

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 8px 16px;
	}

	.history-button {
		background: #d4d4d8;
		/* shadow-base */

		box-shadow: 0px 1px 2px rgba(119, 134, 156, 0.08);
		border-radius: 16px;
		padding: 8px 16px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.table-small {
		button:not(:first-child) {
			margin-left: 0px;
		}

		.ant-table-thead .ant-table-cell {
			font-size: 14px;
		}

		.ant-table-row-level-0:nth-child(odd) {
			background-color: #f3f4f6;
		}

		.ant-table-row-level-0:nth-child(even) {
			background-color: #ffffff;
		}
	}

	.history-search {
		margin-top: 15px;
		margin-bottom: 15px;

		// .ant-btn {
		//	 float: right;
		// }
	}

	.ant-btn-primary,
	.ant-btn-default {
		text-align: center;
	}

	.simple-pagination {
		margin-top: 20px;
		color: black;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.pagination-disabled {
			color: gray;
			cursor: not-allowed;
		}

		.ant-pagination {
			margin-top: 0px;

			.ant-pagination-simple-pager {
				display: none;
			}
		}
	}

	.ant-descriptions-item-label {
		font-weight: 700;
	}

	.ant-descriptions-item-content {
		max-width: 100%;
	}

	.description-list {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.upcoming-status {
		.ant-descriptions-item-content {
			color: #84cc16;
			font-weight: 700;
		}
	}

	.ongoing-status {
		.ant-descriptions-item-content {
			color: #0ea5e9;
			font-weight: 700;
		}
	}

	.finished-status {
		.ant-descriptions-item-content {
			color: #f59e0b;
			font-weight: 700;
		}
	}

	.paused-status {
		.ant-descriptions-item-content {
			color: #000000;
			font-weight: 700;
		}
	}

	.unassigned {
		.ant-descriptions-item-content {
			color: #f31313;
			font-weight: 700;
		}
	}

	.ant-form-item-label {
		font-family: Inter;
		font-weight: 500;
		font-size: 14px;
		color: #6b7280;
	}

	.search-title {
		font-weight: 600;
		font-size: 14px;
		margin-bottom: 30px;
	}

	.ant-form-item-label {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}

	button:not(:first-child) {
		display: inline-block;
		text-align: left;
		margin-left: 20px;
	}

	.ant-pagination {
		text-align: left;
		margin-top: 25px;
	}

	.ant-input {
		margin-right: 0px;
		max-width: 100%;
	}

	form {
		margin-top: 20px;
	}

	.ant-pagination-options::before {
		margin-right: 20px;
		content: "Hiển thị: ";
	}

	.anticon-setting {
		color: #6b7280;
		width: 17px;
		height: 17px;
	}

	.content-box-button {
		text-align: right;
		margin-bottom: 20px;
		margin-top: 20px;
	}

	.content-box-label {
		float: left;
		margin-bottom: 20px;
	}

	.search-box-button {
		text-align: right;
	}

	.search-box-button button {
		margin: 5px;
	}

	/*specs module*/
	.ant-form-item-control-input-content>.input-number {
		width: 500px;
	}

	.ant-pagination-prev button,
	.ant-pagination-next button {
		margin-left: 0px;
	}

	.auction-title {
		font-size: 20px;
		font-weight: bold;
		color: #40a9ff;
	}

	.statute-title {
		font-size: 18px;
		font-weight: bold;
		color: #40a9ff;
	}

	.ant-divider-horizontal {
		margin: 0px 0px 20px 0px;
	}

	.custom-form {
		.ant-input-number {
			width: 20%;
		}
	}

	.register-label {
		margin-left: auto;
		margin-bottom: 20px;
	}

	// .propNameInput {
	//		 margin-left: 61px;
	//		 max-width: 95%;
	// }

	.ant-btn,
	.ant-input,
	.ant-pagination-options::before {
		font-family: Inter;
	}

	.ant-input-affix-wrapper .ant-input {
		box-shadow: none;
	}

	.unassigned-row {
		color: #f31313;
	}

	.ant-table-scroll-horizontal {
		.ant-table-row-level-0:nth-child(odd) {
			background-color: #f3f4f6;
		}

		.ant-table-row-level-0:nth-child(even) {
			background-color: #ffffff;
		}
	}

	// .scroll-table {
	//	 .ant-table-container {
	//		 .ant-table-header .ant-table-sticky-holder {
	//			 top: -27px;
	//			 background-color: #40a9ff;
	//		 }
	//	 }
	// }
}

.report-box {
	.ant-table-scroll-horizontal {
		.ant-table-row-level-0:nth-child(odd) {
			background-color: #ffffff;
		}

		.ant-table-row-level-0:nth-child(even) {
			background-color: #ffffff;
		}
	}
}

.ant-breadcrumb-link {
	font-family: Inter;
	color: #FFFFFF !important;
}

.propInfo-modal {
	.ant-modal-body {
		height: 400px;
		overflow-y: auto;
	}
}

.content-box-no-background {
	@extend .content-box;
	background: #f9f7f7;
	// border: 2px solid #E5E7EB;
}

.custom-list {
	.ant-list-item {
		@extend .content-box;
		margin-top: 0px;
		margin-bottom: 0px;
		padding-bottom: 0px;
		box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(31, 41, 55, 0.1);

		.item-list {
			width: 100%;
		}

		.ant-list-item-meta {
			align-items: center;

			.ant-list-item-meta-content {
				margin-left: 20px;
				justify-content: center;
				height: 100%;
				width: 100%;
			}

			.ant-list-item-meta-title {
				margin-top: 10px;
			}

			.ant-list-item-meta-description {
				color: black;
			}
		}

		.ant-list-item-action {
			align-self: flex-end;
			margin-bottom: 20px;
		}
	}
}

.ant-list-vertical {
	.ant-list-items {
		.item-list {
			@extend .content-box;
			margin-top: 0px;
			margin-bottom: 0px;
			padding-bottom: 0px;
			box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(31, 41, 55, 0.1);

			.ant-list-item-meta-content {
				margin-left: 20px;
				justify-content: center;
				height: 100%;
			}

			.ant-list-item-meta-title {
				margin-top: 10px;
			}
		}
	}
}

.content-horizontal {
	display: flex;
	flex-direction: row;
}

.outside-box-label {
	font-weight: 600;
	font-size: 16px;
	font-family: Inter;
	margin-bottom: 30px;
	margin-left: 30px;
}

/*notification*/
.ant-notification {
	.ant-notification-notice-with-icon {
		font-family: Inter;
		font-weight: 500;
		font-size: 16px;
		color: #374151;
		// display: flex;
		align-items: center;
	}

	.ant-notification-notice-closable {
		padding: 15px;
	}

	.ant-notification-notice-with-icon .ant-notification-notice-description {
		margin-left: 0px;
	}
}

/*table*/
.ant-table {
	.ant-table-thead .ant-table-cell {
		background: #f3f4f6;
		// text-align: center;
		font-family: Inter;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: #3f3f46;
	}

	// .ant-table-thead .ant-table-cell {
	//		 text-overflow: clip;
	// }

	.ant-table-tbody {
		font-family: Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: #18181b;
	}

	.ant-table-row-level-0:nth-child(2n + 2) {
		background-color: #f3f4f6;
	}
}

.scroll-table {

	.ant-table-sticky-holder,
	.ant-table-sticky-scroll {
		top: -27px !important;
	}

	.ant-table-row-level-0:nth-child(2n + 1) {
		background-color: #f3f4f6;
	}

	.ant-table-row-level-0:nth-child(2n + 2) {
		background-color: #ffffff;
	}

	.ant-table .ant-table-thead .ant-table-cell {
		white-space: nowrap;
	}
}

/*popover*/
.ant-popover {
	.ant-popover-placement-bottomLeft {
		background: #ffffff;
		box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(31, 41, 55, 0.1);
		border-radius: 4px;
		padding: 0px;
	}

	.popover-button {
		border: none;
		background: white;
		color: #374151;
		display: flex;
		align-items: center;
		cursor: pointer;
		margin-bottom: 10px;
	}

	.popover-button:hover,
	.popover-button:active,
	.popover-button:focus {
		color: #40a9ff;
	}

	.ant-popover-content a {
		color: #374151;
		font-family: Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		display: flex;
		flex-direction: row;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.ant-popover-arrow {
		display: none;
	}
}

/*pagination*/
.ant-pagination {
	display: block;
	text-align: center;

	.ant-pagination-item {
		border-radius: 50%;
		border: #ffffff;
		font-family: Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		margin-right: 20px;
		margin-left: 20px;
	}

	.ant-pagination-next button,
	.ant-pagination-prev button {
		border-radius: 50%;
		// width: 40px;
		// height: 40px;
		position: relative;
		// bottom: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.ant-pagination-item-active {
		background-color: rgba(0, 0, 0, 0.54);
	}

	.ant-pagination-item-active a {
		color: #fcfcfc;
	}

	.ant-pagination-item-link svg {
		color: #000000;
		width: 16px;
		height: 16px;
	}

	.ant-pagination-options {
		float: right;
	}
}

/*modal*/
.modal-1 {
	min-width: 926px;
	min-height: 244px;
	background-color: white;
	font-family: Inter;

	.ant-modal-content {
		min-width: 926px;
		min-height: 244px;
		background-color: white;
	}

	.ant-modal-header {
		border-bottom: 1px solid #000000;
	}

	.ant-modal-title {
		font-family: Inter;
		font-weight: bold;
		font-size: 20px;
	}

	.ant-form-item-control-input {
		// background: #ffffff;
		// border: 1px solid #e5e7eb;
		// box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
		border-radius: 4px;
		width: 260px;
		height: 32px;
	}

	.ant-input {
		width: 260px;
		height: 32px;
		font-family: Inter;
	}

	.ant-select-selector {
		width: 260px;
		height: 32px;
	}

	.ant-modal-footer {
		border-top: none;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: 50px;
	}

	.ant-modal-body {
		padding-bottom: 10px;
	}

	.ant-radio-input:focus+.ant-radio-inner {
		box-shadow: none;
	}

	.reject-text {

		.ant-form-item-control-input,
		.ant-input {
			width: 100%;
			top: 15px;
		}

		.ant-input-textarea-show-count:after {
			margin-top: 20px;
		}
	}

	.note-textarea {

		.ant-form-item-control,
		.ant-form-item-control-input,
		.ant-form-item-control-input-content,
		.ant-input-textarea-show-count,
		.ant-input {
			width: 100%;
		}
	}

	.radio-box {
		.ant-radio-group {
			text-align: center;
			display: flex;
			width: 100%;
			justify-content: space-around;
		}

		.ant-form-item-control-input {
			border-style: none;
			box-shadow: none;
			width: 100%;
		}
	}

	.ant-form-item-control-input-content {
		width: 100%;
	}

	.modal1-textarea {
		width: 100%;
		height: 100%;
	}

	.ant-col-20>.ant-form-item-control-input {
		width: 96%;
	}

	.select-group>.ant-col-20>.ant-form-item-control-input {
		width: 100%;
	}

	.select-group>.ant-col-20>.ant-form-item-control-input-content {
		width: 100%;
	}

	.select-group>.ant-col-20>.ant-form-item-control-input-content>.ant-input-group-compact>.ant-select-show-arrow>.ant-select-selector {
		width: 100%;
	}
}

.ant-picker {
	width: 100%;
}

.attach-modal {
	height: 300px;
}

/*error-1 : actually warning modal not error modal*/
.error-1 {
	width: 425px;

	.ant-modal-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-bottom: 0px;
	}

	.ant-modal-footer {
		border-top: none;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: 30px;
		padding-top: 30px;
	}

	.ant-modal-body>label {
		font-family: Inter;
		font-weight: bold;
		font-size: 18px;
		color: #1e3a8a;
		margin-top: 15px;
	}

	.ant-modal-body>span {
		font-family: Inter;
		font-weight: 600;
		font-size: 14px;
		color: #374151;
		margin-top: 15px;
		text-align: center;
	}

	/*buttons*/
	.red-button {
		background: #dc2626;
		border: 1px solid #e5e7eb;
		box-sizing: border-box;
		box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
		border-radius: 4px;
		padding: 8px 16px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-family: Inter;
		font-size: 14px;
		color: #ffffff;
	}

	.white-red-border-button {
		border: 1px solid #dc2626;
		box-sizing: border-box;
		box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
		border-radius: 4px;
		font-family: Inter;

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 8px 16px;
	}
}

/*user module*/
.select-group {
	.ant-form-item-control-input {
		width: 100%;
	}

	.ant-form-item-control {
		margin-right: 30px;
	}
}

/*participants module*/
.description-img {
	display: flex;
	flex-direction: row;
}

.description-link {
	display: grid;
}

.description-link a:hover {
	text-decoration: underline;
}

/*tabs*/
/* .ant-tabs-top>div>.ant-tabs-nav {
		margin: auto;
		 display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
} */

.ant-tabs.user-tabs {
	display: flex;
	justify-content: center;
	align-items: center;
}

.user-tabs.only-tab {
	.ant-tabs-nav-list {

		.ant-tabs-tab,
		.ant-tabs-tab:nth-last-child(2) {
			border-radius: 10px;
		}
	}
}

.user-tabs {
	.ant-tabs-nav-list {
		.ant-tabs-tab {
			&:first-child {
				border-radius: 10px 0px 0px 10px;
			}

			&:nth-last-child(2) {
				border-radius: 0px 10px 10px 0px;
			}
		}
	}

	.ant-tabs-nav {
		background: #ffffff;
		border-radius: 20px;
		width: 80%;
	}

	.ant-tabs-nav-list,
	.ant-tabs-tab,
	.ant-tabs-tab-active,
	.ant-tabs-nav-wrap {
		width: 100%;
	}

	.ant-tabs-tab,
	.ant-tabs-tab-active {
		align-items: center;
		justify-content: center;
	}

	.ant-tabs-tab+.ant-tabs-tab {
		margin-left: 0px;
	}

	.ant-tabs-tab-active+.ant-tabs-tab-active {
		margin-left: 0px;
	}

	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: #ffffff;
		font-style: bold;
	}

	.ant-tabs-nav-more {
		display: none;
	}

	.ant-tabs-nav-operations {
		display: none;
	}

	.ant-tabs-tab-active {
		background-color: #40a9ff;
	}

	.ant-tabs-ink-bar {
		display: none;
	}

	.ant-tabs-content-holder {
		width: 100%;
	}

	// .ant-tabs-tab-active{
	//		 width: 100%;
	//		 display: flex;
	//		 justify-content: center;
	//		 align-items: center;
	//		 margin-left: 0px;
	//		 background-color:rgba(85, 190, 237, 0.22);
	// }
}

/*auction-type module*/
.ant-form>.ant-radio-group {
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
}

/*permission module*/
.permission-modal {
	.ant-modal-body {
		padding: 30px;
		height: 600px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.ant-modal-footer {
		text-align: center;
		font-size: 18px;
		border-top: none;
		padding-bottom: 20px;
	}

	.ant-modal-footer .ant-btn {
		font-size: 16px;
		display: inline-flex;
		align-items: center;
	}

	.permission-form .ant-form-item {
		width: 100%;
	}

	.permission-section-title {
		font-weight: bold;
	}

	.ant-checkbox-inner {
		// height: 30px;
		// width: 30px;
		border-color: #40a9ff;
	}

	.ant-form label {
		font-size: 14px;
	}

	.checkbox-group {
		width: 100%;
		margin-left: 30px;
	}

	.checkbox-row {
		margin-bottom: 20px;
	}

	.ant-row>.ant-col-8 {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.ant-modal-title {
		font-size: 20px;
		font-style: bold;
	}

	// .ant-checkbox-inner::after {
	// width: 12px;
	// height: 12px;
	// }

	.ant-checkbox-group {
		display: flex;
		flex-direction: column;
		margin-left: 30px;
		margin-top: 20px;
	}
}

.modal-2 {
	@extend .modal-1;

	.ant-pagination {
		text-align: left;
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.ant-pagination-item {
		margin-left: 10px;
		margin-right: 10px;
	}

	.ant-modal-footer {
		padding-bottom: 20px;
	}

	.ant-pagination-options::before {
		margin-right: 10px;
		content: "Hiển thị: ";
	}
}

.note-col {

	.ant-form-item-control-input,
	.ant-input {
		width: 100%;
	}

	.ant-form-item-control {
		margin-right: 30px;
		margin-top: 15px;
	}
}

.modal-upload {

	.ant-form-item-control-input,
	.ant-form-item-control,
	.ant-form-item-control-input-content {
		border: none;
		box-shadow: none;
		width: 100%;
		top: 15px;
	}

	// .ant-form-item-control-input-content {
	//		 display: inline-block;
	// }

	.ant-upload-list {
		width: 50%;
		// float: right;
	}

	.ant-upload-list,
	.ant-upload-select {
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

.modal-upload-2 {
	display: inline;
}

.clear-input input {
	min-width: 98%;
}

.carousel {
	.custom-swiper {
		height: 100px;
	}

	.custom-swiper-thumbnails {
		height: 50px;
		margin-top: 25px;
		width: 100%;

		.swiper-slide {
			opacity: 0.4;
		}

		.swiper-slide-thumb-active,
		.swiper-slide-active {
			opacity: 1;
			// border: 1px solid #cecece;
		}
	}

	.swiper-button-prev-unique {
		position: absolute;
		top: 68%;
		left: -10px;
		transform: translateY(-50%);
		margin-top: 0px;
		font-size: 30px;
		color: #dcdcdc;
		cursor: pointer;

		&:hover span {
			color: black;
		}
	}

	.swiper-button-next-unique {
		margin-top: 0px;
		position: absolute;
		top: 68%;
		right: 0px;
		transform: translateY(-50%);
		font-size: 30px;
		color: #dcdcdc;
		cursor: pointer;

		&:hover span {
			color: black;
		}
	}

	.swiper-button-prev {
		color: white;
	}

	.swiper-button-next {
		color: white;
	}
}

.care-detail {
	width: 100%;
	// height: 100%;
	background-color: white;

	.ant-modal-header {
		border-bottom: 1px solid #000000;
	}

	.ant-modal-title {
		font-family: Inter;
		font-weight: bold;
		font-size: 20px;
	}

	.ant-form-item-control-input {
		background: #ffffff;
		border: 1px solid #e5e7eb;
		box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
		border-radius: 4px;
		width: 260px;
		height: 32px;
	}

	.ant-modal-footer {
		border-top: none;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: 30px;
		padding-top: 20px;
	}

	.ant-modal-body {
		margin-left: 30px;
		// margin-right: 30px;
		max-height: 430px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.ant-input {
		width: 100%;
		height: 100%;
		font-family: Inter;
	}

	.care-input-textarea .ant-form-item-control-input {
		height: 100%;
	}

	.ant-modal-footer {
		border-top: none;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: 30px;
		padding-top: 20px;
	}

	.ant-row {
		margin-bottom: 20px;
	}

	.ant-form-item-control-input,
	.ant-form-item-control-input-content {
		width: 100%;
	}
}

.custom-modal {
	.ant-modal-content {
		background-color: white;
	}

	.ant-modal-header {
		border-bottom: 1px solid #000000;
	}

	.ant-modal-title {
		font-family: Inter;
		font-weight: bold;
		font-size: 20px;
	}

	.ant-modal-footer {
		border-top: none;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: 20px;
		padding-top: 0px;
	}

	.ant-modal-body {
		overflow-y: auto;
		overflow-x: hidden;
	}

	.ant-input {
		width: 100%;
		height: 100%;
	}

	label {
		margin-right: 20px;
	}

	.ant-col {
		display: flex;
	}

	.ant-row label:first-child {
		font-weight: bold;
	}

	.ant-row .ant-col:nth-child(1) label:first-child {
		width: 161px;
	}

	.ant-row .ant-col:nth-child(2) label:first-child {
		width: 130px;
	}

	.ant-row {
		margin-bottom: 20px;
	}

	.ant-form-item-control-input,
	.ant-form-item-control-input-content {
		width: 100%;
	}
}

.table-disabled-input {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
	height: 32px;
	text-align: center;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	padding: 4px 11px;
	white-space: nowrap;
	min-width: 100px;
}

.anchor-text {
	color: #1890ff;
	text-decoration: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
	-webkit-text-decoration-skip: objects;
	width: 100%;
}

.anchor-test-overflow-custom {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	color: #1890ff;
	text-decoration: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
	-webkit-text-decoration-skip: objects;
}

.form-editor-item {
	&.error .tox-tinymce {
		border: 1px solid red;
	}

	.ant-form-item-label>label:after,
	.ant-form-item-label>label.ant-form-item-no-colon:after {
		display: inline;
		margin-right: 4px;
		color: #ff4d4f;
		font-size: 14px;
		font-family: Inter;
		line-height: 1;
		content: "*";
	}
}

.detail-form-editor {
	.tox-tinymce {
		border: none;
		width: 100%;
	}

	.tox .tox-statusbar {
		display: none;
	}
}

.detail-form-editor .ant-descriptions-item-content {
	margin: -12px auto auto -19px;
}

.file-preview {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	transition: box-shadow 0.3s, border-color 0.3s;
	max-width: 150px;

	&:hover {
		box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f, 0 5px 12px 4px #00000017;
	}

	span {
		padding: 0px 5px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		text-align: center;
	}
}

.ant-form-item-extra .custom-item-extra {
	color: rgba(0, 0, 0, 0.85);
}

.add-upload {

	.ant-upload-list-picture-card-container,
	.ant-upload.ant-upload-select-picture-card {
		width: 130px;
		height: 130px;
		margin: 0 15px 20px 0;
	}
}

.photo-display {
	// display: inline-flex;
	// justify-content: center;
	// align-items: center;
	// flex-direction: row;
	max-width: 500px;
	overflow: auto;
	display: inline-block;
}

.file-wrapper {
	text-decoration: underline;
	display: flex;
	align-items: center;
}

.detail-description {
	width: 560px;
	padding-left: 40px;

	button:not(:first-child) {
		display: inline-block;
		text-align: left;
		margin-left: 0px;
	}

	.ant-btn {
		margin-left: 5px;
	}
}

.balance-pagination {
	.ant-pagination {
		text-align: center;
	}

	.ant-pagination-total-text {
		float: left;
		margin-right: 50px;
	}

	.ant-pagination-options {
		text-align: right;
	}
}

.bold-font {
	font-weight: 700;
}

.headgroup-table {
	.ant-table-row-level-0:nth-child(2n + 1) {
		background-color: #f3f4f6;
	}

	.ant-table-row-level-0:nth-child(2n + 2) {
		background-color: #ffffff;
	}

	.ant-table .ant-table-thead .ant-table-cell {
		white-space: nowrap;
	}

	.ant-table-thead .ant-table-cell {
		border: 1px solid #d9d9d9;
	}
}

.success-nomargin {
	.ant-form-item-has-success {
		margin-bottom: 0px;
	}
}

.ant-table-sticky-scroll-bar {
	display: none;
}

// .ant-table-sticky-holder {
//	 // top: -27px;
//	 color: #0ea5e9;
// }

.footnote-text {
	color: #d9d9d9;
	font-style: italic;
}

.header-space {
	.ant-space-item:not(:first-child) {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.auction-list-box {
	height: 8px;
	width: 8px;
	display: inline-flex;
	position: relative;

	&.red-box {
		background: #f31313;
	}

	&.orange-box {
		background: #fb923c;
	}

	&.blue-box {
		background: #3b82f6;
	}

	&.green-box {
		background: #a3e635;
	}
}

.auction-list-checkbox {
	&.orange-checkbox {

		.ant-checkbox:hover .ant-checkbox-inner,
		&.ant-checkbox-wrapper:hover .ant-checkbox-inner {
			border-color: #fb923c;
		}

		.ant-checkbox-inner {
			background-color: #fb923c;
			border-color: #fb932c;
		}

		.ant-checkbox-checked {
			&:after {
				border: 1px solid #fb923c;
			}
		}
	}

	&.red-checkbox {

		.ant-checkbox:hover .ant-checkbox-inner,
		&.ant-checkbox-wrapper:hover .ant-checkbox-inner {
			border-color: #f31313;
		}

		.ant-checkbox-inner {
			background-color: #f31313;
			border-color: #f31313;
		}

		.ant-checkbox-checked {
			&:after {
				border: 1px solid #f31313;
			}
		}
	}

	&.blue-checkbox {

		.ant-checkbox:hover .ant-checkbox-inner,
		&.ant-checkbox-wrapper:hover .ant-checkbox-inner {
			border-color: #3b82f6;
		}

		.ant-checkbox-inner {
			background-color: #3b82f6;
			border-color: #3b82f6;
		}

		.ant-checkbox-checked {
			&:after {
				border: 1px solid #3b82f6;
			}
		}
	}

	&.green-checkbox {

		.ant-checkbox:hover .ant-checkbox-inner,
		&.ant-checkbox-wrapper:hover .ant-checkbox-inner {
			border-color: #a3e635;
		}

		.ant-checkbox-inner {
			background-color: #a3e635;
			border-color: #a3e635;
		}

		.ant-checkbox-checked {
			&:after {
				border: 1px solid #a3e635;
			}
		}
	}
}

.avatar-uploader {
	text-align: center;
}

.product-statute-list {
	&.ant-collapse>.ant-collapse-item>.ant-collapse-header {
		padding: 0px 0px 0px 0px;
	}

	&.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
		padding: 0px 15px;
		// margin-top: 15px;
		// margin-left: 5px;
		margin: 5px 5px;
		border-left-style: solid;
		border-left-width: 3px;
		border-left-color: #d9d9d9;
	}

	.ant-form-item {
		margin: 0px;
	}

	.bold-font {
		margin: 0px;
	}
}

.detail-data-info {
	display: flex;
	justify-content: space-between;
}

.detail-data-info>div {
	padding: 10px;
}

.content-box .header-block svg:hover {
	fill: #0ea5e9;
}

//

#Draggable {
	padding: 0px;
	border: 1px dashed rgb(229, 229, 229);
	cursor: col-resize;
	order: 2;
}

.detail-data-info .ant-input[disabled],
.detail-data-info .ant-select-disabled .ant-select-selection-item {
	color: black;
}

.ant-input[disabled],
.ant-select-disabled .ant-select-selection-item {
	color: black;
}

// Css for config field item
.config-item .site-tag-plus {
	background: #fff;
	border-style: dashed;
}

.config-item .edit-tag {
	user-select: none;
	margin-top: 5px;
}

.config-item .tag-input {
	width: 78px !important;
	margin-right: 8px !important;
	vertical-align: top;
}

// Custom css modal

.custom-modal {
	.ant-modal-body {
		overflow: auto;
		max-height: calc(100vh - 125px) !important;
	}
}

.ant-modal-body {
	overflow: auto;
	max-height: calc(100vh - 235px);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: #1890ff !important;
	color: #ffffff !important;
}

.Resizer {
	background: #000;
	opacity: 0.2;
	z-index: 1;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-moz-background-clip: padding;
	-webkit-background-clip: padding;
	background-clip: padding-box;
}

.Resizer:hover {
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}

.Resizer.horizontal {
	height: 11px;
	margin: -5px 0;
	border-top: 5px solid rgba(255, 255, 255, 0);
	border-bottom: 5px solid rgba(255, 255, 255, 0);
	cursor: row-resize;
	width: 100%;
}

.Resizer.horizontal:hover {
	border-top: 5px solid rgba(0, 0, 0, 0.5);
	border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
	width: 11px;
	margin: 0 -5px;
	border-left: 5px solid rgba(255, 255, 255, 0);
	border-right: 5px solid rgba(255, 255, 255, 0);
	cursor: col-resize;
}

.Resizer.vertical:hover {
	border-left: 5px solid rgba(0, 0, 0, 0.5);
	border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
	cursor: not-allowed;
}

.Resizer.disabled:hover {
	border-color: transparent;
}

.SplitPane {
	position: inherit !important;
}

.body-block-create-info-init {
	width: 100%;
	height: fit-content;
	overflow: auto;
	display: inline-block;
	position: relative;
}

// .react-pdf__Page__canvas {
//     margin: 0 auto;
//     width: 80% !important;
//     height: 100% !important;
// }

#block-view-pdf.active {
	background: rgba(255, 255, 255, 0.3) !important;
	cursor: grabbing !important;
	cursor: -webkit-grabbing !important;
	transform: scale(1) !important;
}

#block-view-pdf:hover {
	cursor: grab;
}


.ant-tag {
	font-weight: 500;
	border-radius: 4px;
	padding: 2px 6px;
	font-size: 12px;
	cursor: default;

	&.tag-status {
		&_active {
			color: #0D9488;
			background-color: #CCFBF1;
		}

		&_err {
			color: #e11d48;
			background-color: #ffe4e6;
		}
	}
}

.menu-actions {
	.ant-menu-title-content {
		display: flex;
		align-items: center;

		svg {
			margin-right: 10px;
		}
	}

	.ant-menu-item {

		&.ant-menu-item-active:hover,
		&.ant-menu-item-selected {
			background-color: #f5f5f5;
			color: #262c38;
		}
	}

	&.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background-color: transparent;
	}
}

.ant-input-affix-wrapper-disabled .ant-input[disabled] {
	color: black;
	background: transparent;
}

.ant-input-affix-wrapper .ant-input[disabled] {
	color: black;
}

.ant-picker.ant-picker-disabled .ant-picker-input>input[disabled] {
	color: black;
	background: transparent;
}

// Drawer
.custom-drawer {
	.ant-drawer-title {
		// color: #2563eb;
		font-weight: 500;
		font-size: 24px;
	}

	.ant-drawer-header {
		border-bottom: none;
	}

	.ant-drawer-body {
		padding-bottom: 110px;
		padding-top: 0;
	}

	.drawer-section {
		font-weight: 500;
		font-size: 18px;
		color: #374151;
		width: 100%;
	}

	.ant-form-item-label {
		padding-bottom: 0px;
	}

	.ant-divider-dashed {
		color: #d1d5db;
		;
	}

	.ant-drawer-mask {
		background-color: transparent;
	}
}
.mgl10{
	margin-left: 10px;
}
.mgr20{
	margin-right: 20px;
}
.align-items-center{
	align-items: center;
}
.justify-content-center{
	justify-content: center;
}
.break-work{
	word-wrap: break-word;
}
.txt-bold{
	font-weight: bold;
}
// css pdf viewer
// .rpv-core__viewer{
// 	.selection-mode{
// 		.rpv-core__minimal-button{
// 			background-color: transparent;
// 		}
// 		&:hover,
// 		&.active{
// 			background-color: rgb(0 0 0 / 10%);
// 		}
// 	}

// 	.rpv-core__menu-item.rpv-core__menu-item--ltr{
// 		display: block;
// 		margin-left: 0;
// 		padding-left: 20px;
// 	}
// }
.ant-checkbox-disabled .ant-checkbox-inner{
	background-color: #E5E7EB!important;
}
.ant-custom-select{
	.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
		background-color: #FFE2C8!important;
	}
}
.ant-breadcrumb-separator{
	color: #FFFFFF !important;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
	display: none;
}

.content-box .ant-table-scroll-horizontal .ant-table-row-level-0:nth-child(odd){
	background-color: #F9FAFC !important;
}

.content-box form{
	margin: 0;
}
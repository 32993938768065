.home .ant-col {
    display: inline-flex;
    align-self: stretch;



}

.home-card {
    border: 1px solid #F4F4F5;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 40px 40px 20px 20px;
    width: 100%;
}

.home-card ul {
    list-style-type: disc;
}

.home-card .recharts-legend-item-text {
    color: rgb(165, 165, 165) !important;
}

.home-chart-label {
    margin-right: 10px;
}

.chart-label-upper {
    transform: translateY(-20px);
    font-size: 18px;
}

.chart-label-lower {
    transform: translateY(20px);
    font-size: 18px;
    fill: red;
}

.notice-list {
    margin-top: 40px;
    font-size: 16px;
}

.notice-list li {
    margin-bottom: 20px;
}

.ml-10 {
    margin-left: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.mb-40 {
    margin-bottom: 40px;
}

.pb-40 {
    margin-bottom: 40px;
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
    margin-left: 1px;
    margin-right: 10px;
    order: -1;
}

.legend-chart .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--legend-background-color) !important;
    border-color: var(--legend-border-color);
}

.legend-chart .ant-checkbox-input {
    background-color: var(--legend-background-color);
    border-color: var(--legend-border-color);
}

.legend-chart .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: white !important;
}

.legend-chart .ant-checkbox-inner {
    background-color: var(--legend-background-color) !important;
    border-color: var(--legend-background-color) !important;
}

.ant-checkbox-inner {
    border-radius: 3px !important;
}

.ant-checkbox-input:hover {
    outline: none !important;
    border: none !important;
}

.gutter-box {
    padding: 8px 0;
    background: #00a0e9;
}

.main-card {
    margin: auto !important;
    padding: 15px;
    height: 100%;
    display: inline-flex;
    align-self: stretch;
}

.main-card .chart {
    display: flex;
    flex-direction: column;

    background: white;

    height: auto;
    padding: 30px;
    margin: 0 auto;
    width: 100%;
    min-height: 550px;
}

.main-card .chart .time-picker {
    margin: 10px;
}

.main-card .chart .checkbox {
    margin: 10px auto;
}

.main-card .chart-admin {
    display: flex;
    flex-direction: column;

    background: white;

    padding: 30px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.main-card .chart-admin .topbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.legend-chart {
    display: grid;
    grid-template-columns: repeat(5, auto);
    justify-content: center;
    min-height: 50px;
}

.legend-chart>.ant-checkbox-wrapper {
    margin-left: 0 !important;
}

.picker-footer {
    padding: 10px 0;
}

.picker-footer button {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
    cursor: pointer;
    font-size: 12px;
    padding: 0 7px;
    height: 22px;
    margin-right: 5px;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background: #3B82F6 !important;
}

/* Bar chart custom tootip */
.customs-tooltip {
    margin: 0px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    white-space: nowrap;
}

.customs-tooltip .list {
    padding: 0;
    margin: 0;
    padding: 0px 5px;
}

.customs-tooltip .label {
    font-size: 15px;
    margin: 0;
    border-bottom: 1px solid #D0D0D0;
    padding: 2px;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
}

.customs-tooltip .item {
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 10px;
    color: rgb(136, 132, 216);
    font-size: 12px;
    font-weight: bold;
}

.customs-tooltip .item-name {
    width: 40px;
    text-align: right;
}

.customs-tooltip .item-value {
    margin-left: 5px;
}

/* Custom piechart label */
.pie-chart .label-bottom {
    transform: translateY(15px);
    font-size: 20px;
    font-weight: 700;
    color:#374151;
}

.pie-chart .label-center {
    transform: translateY(0);
    font-size: 14px;
    color: #6B7280;
}

.pie-chart .label-top {
    transform: translateY(-15px);
    font-size: 14px;
    color: #6B7280;
}

.pie-chart .recharts-tooltip-item {
    font-weight: bold;
}

/* Custom bar chart */
.bar-chart .recharts-cartesian-axis-tick {
    font-weight: bold;
    color: #253645;
}

.bar-chart .chart__label {
    font-weight: bold;
    fill: #253645;
    color: #253645;
}
